"use client";
import { useLazyQuery } from "@apollo/client";
import { QUERY_CART } from "@/documents/QUERY_CART";
import {
  CartQuery,
  CartQueryVariables,
} from "@/documents/__generated__/QUERY_CART.codegen";
import {
  shoppingSessionReactiveVar,
  useShoppingSession,
} from "@/hooks/session/useShoppingSession";
import { useCallback } from "react";

export const useCart = () => {
  const [query, { data, error, loading }] = useLazyQuery<
    CartQuery,
    CartQueryVariables
  >(QUERY_CART);

  const load = useCallback(
    (restaurantID: number) => {
      if (loading) {
        shoppingSessionReactiveVar({
          data: null,
          loading,
        });
      }
      return query({
        variables: {
          restaurantId: restaurantID,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-first",
      });
    },
    [loading, query],
  );

  useShoppingSession(data?.restaurant.currentShoppingSession);

  return {
    loading,
    error,
    load,
  };
};
