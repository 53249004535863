"use client";
import { FC, PropsWithChildren, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import { useCart } from "@/hooks/cart/useCart";
import {
  shoppingListsReactiveVar,
  useShoppingLists,
} from "@/hooks/lists/useShoppingLists";
import { sessionUserReactiveVar } from "@/app/UserProvider";

const SessionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { load: loadCart, error } = useCart();
  const { load: loadShoppingLists } = useShoppingLists();
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  useEffect(() => {
    if ((!sessionUser?.data || sessionUser?.error) && !sessionUser?.loading) {
      shoppingListsReactiveVar(null);
      shoppingSessionReactiveVar({
        data: null,
        loading: false,
        error,
      });
    }

    // NOTE: prevent loading when signing out is in progress
    if (sessionUser?.data && !sessionUser?.loading && !error) {
      Promise.all([
        loadShoppingLists({
          restaurantId: sessionUser.data.restaurantID,
        }),
        loadCart(sessionUser.data.restaurantID),
      ]);
    }
  }, [
    loadCart,
    loadShoppingLists,
    sessionUser?.data,
    sessionUser?.loading,
    sessionUser?.error,
    error,
  ]);

  return children;
};

export default SessionProvider;
