"use client";
import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  ModalCloseButton,
  Text,
  ModalFooter,
  Button,
  VStack,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import { Link } from "@chakra-ui/next-js";
import Image from "next/image";

type MobileTeaserProps = {
  os: "iOS" | "Android";
};

const MobileTeaser: FC<MobileTeaserProps> = ({ os }) => {
  const { onClose, isOpen } = useDisclosure({
    defaultIsOpen: true,
  });

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <VStack>
            <Image
              src={"/img/apps_showcase.png"}
              alt="Mobile apps showcase"
              width={200}
              height={200}
            />
            <Heading size="md">Before you proceed...</Heading>
          </VStack>
        </ModalHeader>
        <ModalBody>
          <Text>
            Hi, best way to shop with Kimelo on your phone is in the
            application! We&#39;re available on both Android & iOS. To download
            click here.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            as={Link}
            href={
              os === "iOS"
                ? "https://apps.apple.com/us/app/cheetah-restaurant-supplier/id1022886165"
                : "https://play.google.com/store/apps/details?id=com.restaurantcheetah.cheetah_app"
            }
            colorScheme={"midnight_navy"}
            w={"100%"}
          >
            Get the app
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MobileTeaser;
